import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import LoginScreen from "./Login";
import Dashboard from "./Pages/Dashboard";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/dashboard" element={<Dashboard></Dashboard>} />
        </Routes>
      </Router>
    </div>
  );
}
export default withAuthenticator(App);
