import {
  Button,
  Grid,
  View,
  useTheme,
  Flex,
  Text,
  TextField,
  Tabs,
  TabItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ThemeProvider,
  Theme,
  Pagination,
} from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { ViewResult } from "./ViewResult";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import XMLParser from "react-xml-parser";
import "../App.css";
let Monitorinfo = [];
let TempGrade = "";
const Search = (props) => {
  let Result = [];
  let Providers = [];
  let balancepages = "";
  const { tokens } = useTheme();
  const initialState = {
    comp_name: "",
    city: "",
    phone: "",
    state: "",
    mcff: "",
    tollphone: "",
    MC: ""
  };
  const searchIntialState = {
    report_id: "",
    comp_name: "",
    address: "",
    city: "",
    state: "",
    country_code: "",
    zipcode: "",
    MC: ""
  };
  let toast1 = false;
  // const [toast1, setToast1] = useState(false);
  const [Company_Name_Error, setCompany_Name_Error] = useState(false);
  const [City_error, setCity_error] = useState(false);
  const [State_error, setState_error] = useState(false);
  const [MCFF_error, setMCFF_error] = useState(false);
  const [Phone_error, setPhone_error] = useState(false);
  const [Tollno_error, setTollno_error] = useState(false);

  const [SearchTableView, setSearchTableView] = useState(false);
  const [Bool, setBool] = useState(false);
  //const [ClearTableView, setClearTableView] = useState(true);
  const [resultView, setResultView] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [McNumber, setMcNumber] = useState("")
  const [searchReesponse, setSearchReesponse] = useState();
  const [searchState, setSearchState] = useState([searchIntialState]);

  const [reportArray, setReportArray] = useState([searchIntialState]);
  const [index, setIndex] = useState(0);
  const [Reportdata, setReportdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const LiveAPIEndpoint = "https://api.transcredit.com/Service1.asmx?"
  const DevAPIEndpoint = "https://transcreditapi.rcktechiees.com/Service1.asmx?"
  const ApiKey = "rcktestapiservice"  //rcktestapiservice  //BF7hD2RaxnPQ91aH //4R46mGRKK6lP2Gni


  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  const buttonStyle = {
    size: "small",
    variation: "primary",
    color: "#fff",
    height: "2rem",
    float: "center",
    background: "#34a853",
    border: "#34a853"
  };

  const clearStyle = {
    backgroundColor: "#ddd",
    size: "small",
    color: "#000",
    height: "2rem",
    border: "rgb(244 67 54)",
    float: "center",
  };

  const theme: Theme = {
    name: "table-theme",

    tokens: {
      components: {
        table: {
          row: {
            hover: {
              backgroundColor: { value: "{colors.blue.20}" },
            },

            striped: {
              backgroundColor: { value: "{colors.blue.10}" },
            },
          },

          header: {
            color: { value: "{colors.blue.80}" },
            fontSize: { value: "{fontSizes.small}" },
          },

          data: {
            fontWeight: { value: "{fontWeights.small}" },
          },
        },
      },
    },
  };

  useEffect(() => {
    setReportArray([searchIntialState]);
    setisLoading(false);
  }, []);

  const viewResult = async (report_id) => {
    try {
      let searchReportXml = `<?xml version="1.0" encoding="utf-8"?>
<soap:Envelope 
xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
xmlns:xsd="http://www.w3.org/2001/XMLSchema" 
xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body>
        <getCompleteCreditV2 xmlns="http://tempuri.org/">
            <API_key>${ApiKey}</API_key>
            <ReportID>${report_id}</ReportID>
        </getCompleteCreditV2>
    </soap:Body>
</soap:Envelope>`;
      // console.log(searchReportXml + "report");

      axios
        .post(
          DevAPIEndpoint + "op=getCompleteCreditV2",
          searchReportXml,
          {
            headers: { "Content-Type": "text/xml" },
          }
        )
        .then((res) => {
          let tempArr = [];

          const jsonDataFromXml = new XMLParser().parseFromString(res.data)
            .children[0].children[0].children[0].children;
          console.log("getCompleteCreditV2 : ", jsonDataFromXml);

          jsonDataFromXml.map((x) => {
            // if (x.name == "ReportID") {
            //   tempArr.push({ reportId: x.value });
            // }

            if (x.name == "CompanyName") {
              tempArr = { ...tempArr, comp_name: x.value };
            }
            if (x.name == "CreditScore") {
              tempArr = { ...tempArr, credit_score: x.value };
            }
            if (x.name == "Days_To_Pay") {
              tempArr = { ...tempArr, Day_to_day: x.value };
            }
            if (x.name == "Phone") {
              tempArr = { ...tempArr, phone: x.value };
            }
            if (x.name == "Address") {
              tempArr = { ...tempArr, address: x.value };
            }
            if (x.name == "City") {
              tempArr = { ...tempArr, city: x.value };
            }
            if (x.name == "State") {
              tempArr = { ...tempArr, state: x.value };
            }
            if (x.name == "Zip") {
              tempArr = { ...tempArr, zipcode: x.value };
            }
            if (x.name == "DOT") {
              tempArr = { ...tempArr, MC: x.value };
              //setMcNumber(x.value)
            }
            if (x.name == "CreditReferences") {
              x.children.map((y) => {
                if (y.name == "ReferencesDetails") {
                  y.children.map((z) => {
                    if (z.name == "Providers") {
                      Providers.push(parseInt(z.value, 10));
                    }
                  });
                }
              });
              tempArr = {
                ...tempArr,
                providers: Providers.reduce((a, b) => a + b, 0),
              };

              // tempArr.push({ providers: Providers.reduce((a, b) => a + b, 0) });
            }
          });
          //console.log(tempArr, "tempArr");
          setReportdata(tempArr);
          if (tempArr.credit_score >= 90 || (tempArr.credit_score < 90 && tempArr.credit_score >= 76 && tempArr.providers >= 15) || (tempArr.credit_score < 76 && tempArr.credit_score >= 60)) {
            SendingMailAPI(tempArr)
          }
          // else {
          //   toast.success("not under A,B or C")
          // }
          setResultView(true);
          setIndex(1);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const SendingMailAPI = async (data) => {
    let Grade = "";
    const Username = localStorage.getItem("Username")

    if (data.credit_score >= 90) {
      Grade = "A - APPROVED, it is lowest risk"
    }
    else if (data.credit_score < 90 && data.credit_score >= 76 && data.providers >= 15) {
      Grade = "B - APPROVED, it is moderate risk"
    }
    else if (data.credit_score < 76 && data.credit_score >= 60) {
      Grade = "C - High Risk - Check with Office for Approval."
    }

    let MailDetails = `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope 
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
        xmlns:xsd="http://www.w3.org/2001/XMLSchema" 
        xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
            <emailsent xmlns="http://tempuri.org/">
                <API_key>${ApiKey}</API_key>
                <Custname>${Username==null||Username==""?"-":Username}</Custname>
                <Company_Name>${data.comp_name}</Company_Name>
                <MC>${data.MC==null||data.MC==""?"-":data.MC}</MC>
                <City>${data.city}</City>
                <State>${data.state}</State>
                <Address>${data.address}</Address>
                <Phone_Number>${data.phone}</Phone_Number>
                <Creditscore>${data.credit_score}</Creditscore>
                <ZipCode>${data.zipcode}</ZipCode>
                <Daystopay>${data.Day_to_day}</Daystopay>
                <Grade>${Grade}</Grade>  
            </emailsent>
        </soap:Body>
    </soap:Envelope>`;

    axios
      .post(
        DevAPIEndpoint + "op=emailsent",
        MailDetails,
        {
          headers: { "Content-Type": "text/xml" },
        }
      )
      .then((res) => {
        console.log("Mail Sent Successfully");
        // toast.success("Mail Sent Successfully");
      })
      .catch((err) => {
        // toast.error("Error While sending Mail");
        console.log("Error While sending Mail : ", err);
      });
  }



  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }
  const clearInfo = async () => {
    setisLoading(false);
    setResultView(false);
    setFormState(initialState);
    setSearchTableView(false);
  };

  const saveAction = async () => {
    Monitorinfo = [];

    try {
      setisLoading(true);
      let companyname = "";
      if (formState.comp_name.includes("&")) {
        companyname = formState.comp_name.replace("&", "&amp;");
      } else {
        companyname = formState.comp_name;
      }

      let searchReqXML = `<?xml version="1.0" encoding="utf-8"?>
<soap:Envelope 
xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" 
xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
xmlns:xsd="http://www.w3.org/2001/XMLSchema">
    <soap:Body>
        <getReportSearchV2 xmlns="http://tempuri.org/">
            <API_key>${ApiKey}</API_key>
            <Company_Name>${companyname}</Company_Name>
            <City>${formState.city}</City>
            <State>${formState.state}</State>
            <MC_FF>${formState.mcff}</MC_FF>
            <Phone_Number>${formState.phone}</Phone_Number>
            <Toll_Free_Number>${formState.tollphone}</Toll_Free_Number>
        </getReportSearchV2>
    </soap:Body>
</soap:Envelope>`;


      axios
        .post(
          DevAPIEndpoint + "op=getReportSearchV2",
          searchReqXML,
          {
            headers: { "Content-Type": "text/xml" },
          }
        )
        .then((res) => {
          const jsonDataFromXmlcheck = new XMLParser().parseFromString(res.data)
            .children[0].children[0].children[0].children;

          jsonDataFromXmlcheck.map((z) => {
            if (z.name == "NoOfRecords") {
              if (z.value == 0) {
                setBool(true);
                toast1 = false;
                setSearchTableView(false);
                setisLoading(false);
                toast.error("No Records Found");
              } else {
                setBool(false);
              }
            }
          });
          if (Bool == false) {
            const jsonDataFromXml = new XMLParser().parseFromString(res.data)
              .children[0].children[0].children[0].children[2].children;
            //  console.log("vanthute", jsonDataFromXml.length);
            let tempArr = [];
            for (var t = 0; t < jsonDataFromXml.length; t++) {
              jsonDataFromXml[t].children.map((y) => {
                if (y.name == "ReportID") {
                  let obj = {
                    report_id: y.value,
                  };
                  tempArr = obj;
                }
                if (y.name == "CompanyName") {
                  tempArr = {
                    ...tempArr,
                    comp_name: y.value.replace("&amp;", "&"),
                  };
                }
                if (y.name == "Address") {
                  tempArr = { ...tempArr, address: y.value };
                }
                if (y.name == "City") {
                  tempArr = { ...tempArr, city: y.value };
                }
                if (y.name == "State") {
                  tempArr = { ...tempArr, state: y.value };
                }
                if (y.name == "ZipCode") {
                  tempArr = { ...tempArr, zipcode: y.value };
                }
                if (y.name == "CountryCode") {
                  tempArr = { ...tempArr, countrycode: y.value };
                }
                if (Object.keys(tempArr).length == 7) {
                  Result.push(tempArr);
                }
              });
            }
            // setReportArray(Result);

            setSearchTableView(true);

            setisLoading(false);

            let pendingitems = [];
            if (Result.length > 3) {
              balancepages = Result.length % 3;

              if (balancepages != 0) {
                let position = Result.length - balancepages - 1;
                let t = 1;
                for (let j = 0; j <= Result.length; j++) {
                  if (j == position + t) {
                    t = t + 1;

                    if (Result[j] != undefined) {
                      pendingitems.push(Result[j]);
                    }
                  }
                }
              }
            }
            let k = 2;
            if (Result.length > 3) {
              let pagesize;
              if (Result.length / 3) {
                pagesize = Result.length / 3;
              }
              let pagesizeSplited;
              if (pagesize.toString().split(".")[1] != undefined) {
                pagesizeSplited = pagesize.toString().split(".")[1][0];
              }

              if (Result.length % 3 == 0) {
                settotalPages(Math.round(pagesize));
              } else if (pagesizeSplited && pagesizeSplited > 3) {
                settotalPages(Math.round(pagesize));
              } else if (pagesizeSplited && pagesizeSplited <= 3) {
                settotalPages(Math.round(pagesize) + 1);
              }

              let temparray = [];

              for (let i = 0; i <= Result.length; i++) {
                if (Result[i] != undefined) {
                  temparray.push(Result[i]);
                  //temparray.push({switchField:sample[i].isActive})

                  delete Result[i];

                  if (i == k) {
                    k = k + 3;
                    Monitorinfo.push(temparray);
                    temparray = [];
                  }
                }
              }
              if (balancepages != 0) {
                if (balancepages != undefined) {
                  Monitorinfo.push(pendingitems);
                }
              }

              //  console.log("dev1:", Monitorinfo);
              setReportArray(Monitorinfo[0]);
              setCurrentPageIndex(1);
            } else {
              let temparray = [];
              Result.map((x) => {
                temparray.push(x);
              });
              Monitorinfo.push(temparray);
              setReportArray(Monitorinfo[0]);
              settotalPages(1);
              setCurrentPageIndex(1);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setisLoading(false);
          setBool(false);
          // setToast1(true);
          toast1 = true;
          setSearchTableView(false);
        });
    } catch (err) {
      console.log(err);
      toast.error("Failed");
      // alert("mp");
    } finally {
    }
  };

  //Validation
  const validate = () => {
    if (
      formState.comp_name != "" ||
      formState.city != "" ||
      formState.state != "" ||
      formState.mcff != "" ||
      formState.phone != "" ||
      formState.tollphone != ""
    ) {
      setCompany_Name_Error(false);
      setCity_error(false);
      setState_error(false);
      setMCFF_error(false);
      setPhone_error(false);
      setTollno_error(false);
      saveAction();
    } else if (
      formState.comp_name == "" ||
      formState.city == "" ||
      formState.state == "" ||
      formState.mcff == "" ||
      formState.phone == "" ||
      formState.tollphone == ""
    ) {
      setCompany_Name_Error(true);
      setCity_error(true);
      setState_error(true);
      setMCFF_error(true);
      setPhone_error(true);
      setTollno_error(true);
    }
  };

  //pagination functions

  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const [totalPages, settotalPages] = useState(2);
  const handleOnChange = (newPageIndex, prevPageIndex) => {
    // console.log(
    //   `handleOnChange \n - newPageIndex: ${newPageIndex} \n - prevPageIndex: ${prevPageIndex}`
    // );
    setReportArray(Monitorinfo[newPageIndex - 1]);
    setCurrentPageIndex(newPageIndex);
  };
  const handleNextPage = () => {
    // console.log("handleNextPage");
    setCurrentPageIndex(currentPageIndex + 1);
    setReportArray(Monitorinfo[currentPageIndex]);
  };

  const handlePreviousPage = () => {
    // console.log("handlePreviousPage");
    setReportArray(Monitorinfo[currentPageIndex - 1]);
    setCurrentPageIndex(currentPageIndex - 1);
  };

  return (
    <div>
      <ToastContainer autoClose={1500} limit={0} />
      <View
        gap={tokens.space.small}
        display="flex"
        justifyContent="center"
        paddingTop="30px"
        paddingBottom="30px"
        fontFamily={'Open Sans ,sans - serif'}
      >
        <View className="grade-view"
          width="90%"
          backgroundColor={tokens.colors.background.primary}
          style={shadowProp}
          marginTop={"4em"}
        >
          <Tabs className="tab-section" currentIndex={index} onChange={(i: number) => setIndex(i)}>
            <TabItem className="tabitem" title="Search Company" >
              <View className="search-view">
                <Flex className="search-flex"
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  marginTop="1rem"
                  gap="2rem"
                >
                  <TextField className="TextField"
                    id="comp_name_field"
                    onKeyPress={(e) => e.key === "Enter" && validate()}
                    hasError={Company_Name_Error}
                    errorMessage="Enter Company Name"
                    onChange={(event) =>
                      setInput("comp_name", event.target.value)
                    }
                    value={formState.comp_name}
                    label={<Text>Company Name</Text>}
                    size="small"
                    width="30%"
                    autoComplete="new-password"
                  ></TextField>

                  <TextField className="TextField"
                    onKeyPress={(e) => e.key === "Enter" && validate()}
                    // hasError={City_error}
                    // errorMessage="Enter City"
                    onPaste={(e) => e.replace(/[0-9]|[^\w\s]/gi, "")}
                    onChange={(event) =>
                      setInput(
                        "city",
                        event.target.value.replace(/[0-9]|[^\w\s]/gi, "")
                      )
                    }
                    value={formState.city}
                    label={
                      <Text className="text-field">
                        City
                        {/* <Text as="span" fontSize="0.8rem" color="red">
                                            {' '}
                                            *
                                        </Text> */}
                      </Text>
                    }
                    size="small"
                    width="30%"
                    autoComplete="new-password"
                  ></TextField>

                </Flex>
                <Flex className="search-flex" direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  marginTop="1rem"
                  gap="2rem">
                  <TextField className="TextField"
                    id="comp_name_field"
                    onKeyPress={(e) => e.key === "Enter" && validate()}
                    // hasError={State_error}
                    // errorMessage="Enter State"
                    onPaste={(e) => e.replace(/[0-9]|[^\w\s]/gi, "")}
                    onChange={(event) =>
                      setInput(
                        "state",
                        event.target.value.replace(/[0-9]|[^\w\s]/gi, "")
                      )
                    }
                    value={formState.state}
                    label={<Text className="text-field">State</Text>}
                    size="small"
                    width="30%"
                    autoComplete="new-password"
                  ></TextField>
                  <TextField className="TextField"
                    onKeyPress={(e) => e.key === "Enter" && validate()}
                    hasError={MCFF_error}
                    errorMessage="Enter MCFF"
                    onPaste={(e) => e.replace(/[a-z]|[^\w\s]/gi, "")}
                    onChange={(event) =>
                      setInput(
                        "mcff",
                        event.target.value.replace(/[a-z]|[^\w\s]/gi, "")
                      )
                    }
                    value={formState.mcff}
                    label={<Text>MC_FF</Text>}
                    size="small"
                    width="30%"
                    autoComplete="new-password"
                  ></TextField>

                </Flex>
                <Flex className="search-flex"
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  marginTop="1rem"
                  gap="2rem"
                >


                  <TextField className="TextField"
                    onKeyPress={(e) => e.key === "Enter" && validate()}
                    hasError={Phone_error}
                    errorMessage="Enter Phone Number"
                    onPaste={(e) => e.replace(/[aA-zZ]|[^\w\s]/gi, "")}
                    onChange={(event) =>
                      setInput(
                        "phone",
                        event.target.value
                          .replace(/[aA-zZ]|[^\w\s]/gi, "")

                          .toString()
                          .slice(0, 15)
                      )
                    }
                    value={formState.phone}
                    label={<Text>Phone Number</Text>}
                    size="small"
                    width="30%"
                    autoComplete="new-password"
                  ></TextField>
                  <TextField className="TextField"
                    onKeyPress={(e) => e.key === "Enter" && validate()}
                    // hasError={Tollno_error}
                    // errorMessage="Enter TollNo"
                    onPaste={(e) => e.replace(/[aA-zZ]|[^\w\s]/gi, "")}
                    onChange={(event) =>
                      setInput(
                        "tollphone",
                        event.target.value
                          .replace(/[aA-zZ]|[^\w\s]/gi, "")

                          .toString()
                          .slice(0, 15)
                      )
                    }
                    value={formState.tollphone}
                    label={<Text className="text-field">Toll Free Number</Text>}
                    size="small"
                    width="30%"
                    autoComplete="new-password"
                  ></TextField>
                </Flex>

              </View>

              <Grid justifyContent="center">
                <View marginTop="20px" marginBottom="20px">
                  <Button className="red-btn"
                    isLoading={isLoading}
                    loadingText="Searching..."
                    style={buttonStyle}
                    onClick={validate}
                  >
                    Search
                  </Button>
                  <Button className="red-btn"
                    marginLeft={"1rem"}
                    style={clearStyle}
                    onClick={clearInfo}
                  >
                    Clear
                  </Button>
                </View>
              </Grid>
              <Flex justifyContent={"center"}>
                <View></View>
                <View paddingBottom={"20px"} className="amplify_table_grid">
                  {SearchTableView && (
                    <Table className="amplify_table" marginBottom={"10px"} highlightOnHover variation="striped" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell as="th" color="#000">Report ID</TableCell>
                          <TableCell as="th" color="#000">Company Name</TableCell>
                          <TableCell as="th" color="#000">Address</TableCell>
                          <TableCell as="th" color="#000">City</TableCell>
                          <TableCell as="th" color="#000">State</TableCell>
                          <TableCell as="th" color="#000">Zipcode</TableCell>
                          <TableCell as="th" color="#000">Country</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportArray?.map((row) => (
                          <TableRow key={row?.report_id}>
                            <TableCell
                              fontSize={tokens.fontSizes.xs}
                              component="th"
                              scope="row"
                            >
                              <Button color="#005566" fontSize="14px"
                                variation="link"
                                onClick={(e) => {
                                  viewResult(row?.report_id);
                                }}
                              >
                                {row?.report_id}
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.comp_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.address}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.city}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.state}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.zipcode}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.countrycode}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                  {SearchTableView && (
                    <View className="pagination-view">
                      <Pagination
                        currentPage={currentPageIndex}
                        totalPages={totalPages}
                        onChange={handleOnChange}
                        onNext={handleNextPage}
                        onPrevious={handlePreviousPage}
                      />
                    </View>
                  )}
                </View>
                <View></View>
              </Flex>


            </TabItem>
            {resultView && (
              <TabItem className="tabitem" title="View Results" isDisabled={false}>
                <ViewResult data={Reportdata}></ViewResult>
              </TabItem>
            )}
          </Tabs>
        </View>
      </View>
    </div>
  );
};
export default Search;
