import { useState,useRef } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  useTheme,
  View,
  Grid,
  Image,
  Flex,
  PasswordField,
  TextField,
  Button,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
//import { Button } from 'react-bootstrap';
import "./Login.css";
const LoginScreen = () => {
  const initialState = { name: "", password: "" };
  const [formState, setFormState] = useState(initialState);
  const [isLoading, setisLoading] = useState(false);
  const [Username_error, setUsername_error] = useState(false);
  const [Password_error, setPassword_error] = useState(false);

  ///17:59
  const [InvalidCredentials_error, setInvalidCredentials_error] = useState(false);

  const inputpasswordRef = useRef(null);

  const navigate = useNavigate();
  const { tokens } = useTheme();

  const verifyCredentials = async () => {
    try {
      localStorage.setItem("Username",formState.name)
      setisLoading(true);
      Auth.signIn(formState.name, formState.password)
        .then((user) => {
         // console.log("loginerr1",user)
          navigate("/dashboard");
          setisLoading(false);
        })
        .catch((err) => {
          console.log("err",err)
          setisLoading(false);
          setInvalidCredentials_error(true)
          // setUsername_error(true);
          // setPassword_error(true);
          //
        });
    } catch (err) {
      console.log("error msg", err);
    }
  };

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  const buttonStyle = {
    size: "small",
    variation: "primary",
    color: "#fff",
    border: "#639",
    float: "center",
  };

  return (
    <View backgroundColor={"#eff0f0"} minHeight={"100vh"}>
      <Flex justifyContent={"center"}>
        <Image className="login-logo"
          width={"17rem"}
          alignSelf={"center"}
          paddingTop={"5rem"}
          src="https://18wheelfunding.com/wp-content/uploads/2023/03/18WF-New-logo-1.png"
        ></Image>
      </Flex>

      <Flex justifyContent={"center"} alignItems={"center"}>

        <View className="login-grid" width={"30%"} backgroundColor={"#fff"} justifyContent={"center"} alignItems={"center"}>
          <Text fontSize={"25px"} marginTop={"0px"}>
            Login
          </Text>
          <Text fontSize={"15px"} color={"#64748b"} marginBottom={"15px"}>
            Welcome Back! Please enter your details.
          </Text>
          <TextField
            onChange={(event) => setInput("name", event.target.value)}
            hasError={InvalidCredentials_error}
           // errorMessage="Invalid Credentials"
            onKeyPress={(e) => e.key === "Enter" ? inputpasswordRef.current.focus():""}
            value={formState.name}
            label="Username"
            size="small"
            width="100%"
            marginBottom={"10px"}
          ></TextField>

          <PasswordField
            onChange={(event) => setInput("password", event.target.value)}
            hasError={InvalidCredentials_error}
            errorMessage="Invalid Credentials"
            ref={inputpasswordRef}
            onKeyPress={(e) => e.key === "Enter" && verifyCredentials()}
            value={formState.password}
            label="Password"
            size="small"
            width="100%"
            marginBottom={"20px"}
          ></PasswordField>

          <Button
            onClick={verifyCredentials}
            isLoading={isLoading}
            loadingText="Logging in..."
            style={buttonStyle}
            backgroundColor={"#34a853"}
            width="100%"
          >
            Login
          </Button>
        </View>
      </Flex>
    </View>
  );
};

export default LoginScreen;
