import {Link,Flex} from '@aws-amplify/ui-react'

const linkStyle ={
    color : "white",
    margin:"1rem",
};


const LeftNaveMenu = () => {
    return (<div>
        <Flex
            direction="row"
            justifyContent="space-around"
            alignItems="flex-end"
            gap="1rem"
        >
            {/* <Link style={linkStyle} href="/dashboard">Company Search</Link> */}
            {/* <Link style={linkStyle} href="/upload"></Link> */}
        </Flex>
    </div>
    );
};
export default LeftNaveMenu;