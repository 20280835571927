import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, Flex, Button, useTheme } from "@aws-amplify/ui-react";
import LeftNaveMenu from "./LeftNaveMenu";
import { Auth } from "aws-amplify";
import "./Header.css"

const Header = () => {
  const Navigate = useNavigate();
  const signOut = () => {
    Auth.signOut()
      .then((data) => {
        localStorage.clear()
        Navigate("/");
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  const { tokens } = useTheme();

  const buttonStyle = {
    backgroundColor: "rgb(244 67 54)",
    border: "rgb(244 67 54)",
    size: "small",
    variation: "primary",
    color: "#fff",
    height: "2rem",
    float: "center",
  };
  return (
    <Flex className="header-flex"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="#000"
      padding="14px"
      position="fixed"
      width="100%"
      top="0"
      boxShadow="rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px"

    >
      <Image
        src="https://18wheelfunding.com/wp-content/uploads/2023/03/18WF-New-logo-1.png"
        width="9em"
        className="header-logo"
      ></Image>
      <LeftNaveMenu></LeftNaveMenu>
      <Button className="signout-btn" onClick={signOut} style={buttonStyle}>
        Signout
      </Button>
    </Flex>
  );
};
export default Header;
